.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F3F0;
  overflow: hidden;
}

/*////////////////////////////*/

.StartContainer {
  width: 80%;
  height: 80%;

  text-align: center;
  align-items: center;
  justify-content: center;
  
  display: flex;
  flex-direction: column;
}

.StartLogo {
  width: 30%;
  position: fixed;
  top: 3%;

  @media only screen and (max-width: 768px) {
      width: 50%;
  }
}

.StartText {
  
  font-style: italic;
}

.Container {
  width: 100%;
  height: 60%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.StartInput {
  width:200px;
  padding:10px;
  font-size: 1.5em;
  border: none;
  background-color: #F7F3F0;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;

}

.NameText {
  position: fixed;
  top: 36%;
  font-style: italic;
}

.StartButton {
  position: fixed;
  background-color: #F7F3F0;
  bottom: 10%;
  font-style: italic;
  font-weight: bold;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;
}

.CreateButton {
  position: fixed;
  background-color: #F7F3F0;
  bottom: 45%;
  font-style: italic;
  font-weight: bold;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;
}

.JoinButton {
  position: fixed;
  background-color: #F7F3F0;
  bottom: 33%;
  font-style: italic;
  font-weight: bold;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;
}



/*////////////////////////////*/

.GameLogo {

  width: 10%;
  position: fixed;
  top: 2%;
  left: 2%;

  @media only screen and (max-width: 768px) {
      width: 35%;
      top: 2%;
      left: 4%;
  }

}

.GameInfoContainer {
  font-style: italic;
  line-height: 1em;
  width: 50%;
  position: fixed;
  top: 2%;
  right: 4%;
  text-align: right;
}

.GameButton {
  font-style: italic;
  font-weight: bold;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;
  position: fixed;
  background-color: #F7F3F0;
  bottom: 18%;
}

.ReturnButton {
  font-style: italic;
  font-weight: bold;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;
  position: fixed;
  background-color: #F7F3F0;
  bottom: 6%;
}

.LobbyButton {
  font-style: italic;
  font-weight: bold;
  width: auto;
  padding: 1em;
  font-size: 1.5em;
  position: fixed;
  background-color: #F7F3F0;
  bottom: 10%;
}

.GameText {
  font-style: italic;
}

.LobbyText {
  font-style: italic;
  position: fixed;
  top: 20%;
}

.LobbyContainer {
  width: 100%;
  height: 60%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PlayerList {

  text-align: center;
  list-style-position: outside;
  list-style-type: square;
  font-size: large;
}

/*//////////////*/

.Game {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.GameContainer {
  width: 80%;
  height: 80%;

  text-align: center;
  align-items: center;
  justify-content: center;
  
  display: flex;
  flex-direction: column;
}

.Container {
  width: 100%;
  height: 60%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.BoardContainer {
  width: 100%;
  height: 70%;
  
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.Board {
  position: fixed;
  top: 28%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.BoardWord {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.VoteText {
  font-style: italic;
  position: fixed;
  top: 15%;
}

.TopicText {
  font-style: italic;
  position: fixed;
  top: 15%;
}

.RoleText {
  font-style: italic;
  position: fixed;
  top: 20%;
}

.Divider {
  height: 1px;
  width: 300px;
  position: fixed;
  top: 48%;
  background-color: black;
  border: none;
}

.ClueBoxContainer {
  font-style: italic;
  width: 90%;
  height: 48%;
  position: fixed;
  bottom: 3%;
  text-align: right;
}

.ClueText {
  position: fixed;
  top: 48%;
}

.ClueBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ClueInput {
  width:220px;
  padding:8px;
  font-size: 1.5em;
  border: none;
  background-color: #F7F3F0;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;
}

.ClueSubmitContainer {
  position: fixed;
  bottom: 4%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ClueSubmit {
  margin-left: 3%;
  font-style: italic;
  font-weight: bold;
  width: auto;
  padding: 0.5em;
  font-size: 1em;
  background-color: #F7F3F0;
}

.ClueList {
  width: 70%;
  text-align: center;
  list-style-position: outside;
  list-style-type: square;
  font-size: medium;
  position: fixed;
  bottom: 3%;
  line-height: 9px;
}

.TurnIndicator {
  position: fixed;
  top: 54%;
}

.VoteIndicator {
  position: fixed;
  top: 49%;
}



.VotingContainer {
  width: 100%;
  height: 70%;
  
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: top;

}

.VoteButton {
  margin-left: 5%;
  font-style: italic;
  background-color: #F7F3F0;
  font-weight: bold;
  width: auto;
  padding: 0.5em;
  border-radius: 5%;
  font-size: 1em;
}

.ReadyButton {
  font-style: italic;
  font-weight: bold;
  background-color: #F7F3F0;
  width: 50%;
  padding: 1em;
  font-size: 1.5em;

  margin-top: 20px;
}

/*///////////////////*/

.popup {
  display: flex;
  position: fixed;
  height: 70%;
  width: 70%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white; /* Ensure this is a solid color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;

  justify-content: center;
  align-items: center;
}

.popup-content {
  text-align: center;
  justify-content: center;
  align-items: center;
  
}
